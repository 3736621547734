import Layout from "@/components/App/Layout";
import SEO from "@/components/SEO";
import FrequentlyAskedQuestions from "@/components/Shared/Design/FrequentlyAskedQuestions";
import BreadCrumb from "@/components/Shared/extra/BreadCrumb";
import { StructuredDataSnippetTag } from "@/components/Shared/StructuredDataTag";
import { SEOType } from "@/types";
import { graphql, PageProps } from "gatsby";
import React, { Fragment } from "react";
import SolutionDetailView from "./SolutionDetailView";

export const query = graphql`
  query ($slug: String!) {
    contentfulSolutionModelRef(slug: { eq: $slug }) {
      detailedPage {
        heading
        #SEO
        seo {
          metaTitle
          metaDescription
          metaUrl
          metaAuthor
          metaKeywords
          metaImage {
            file {
              url
            }
          }
        }
        frequentlyAskedQuestions {
          id
          question
          answer {
            answer
          }
        }

        structuredDataSnippets {
          snippet {
            id
            snippet
          }
        }
        content {
          raw
        }

        footerContent {
          raw
        }
      }
    }
  }
`;

interface SolutionDetailTemplateProps extends PageProps {
  data: {
    contentfulSolutionModelRef: {
      detailedPage: {
        heading: string;
        seo: SEOType;
        structuredDataSnippets: any;
        frequentlyAskedQuestions: { question: string; id: string; answer: { answer: string } }[];
        content: {
          raw: any;
          references: any[];
        };
        footerContent?: any;
      };
    };
  };
}

const SolutionDetailTemplate: React.FC<SolutionDetailTemplateProps> = ({
  data: { contentfulSolutionModelRef },
}) => {
  return (
    <Layout footerContent={contentfulSolutionModelRef.detailedPage.footerContent}>
      <SEO contentfulSeo={contentfulSolutionModelRef.detailedPage.seo} />
      <BreadCrumb
        pageTitle={contentfulSolutionModelRef.detailedPage.heading}
        paths={[
          { path: "/", pathName: "Home" },
          { path: "/solutions/", pathName: "Solutions" },
          {
            path: "/",
            pathName: contentfulSolutionModelRef.detailedPage.heading,
          },
        ]}
      />
      <br />
      <StructuredDataSnippetTag
        snippets={contentfulSolutionModelRef.detailedPage.structuredDataSnippets}
      />
      <SolutionDetailView content={contentfulSolutionModelRef?.detailedPage?.content} />
      {contentfulSolutionModelRef.detailedPage.frequentlyAskedQuestions?.length ? (
        <Fragment>
          <br />
          <FrequentlyAskedQuestions
            list={contentfulSolutionModelRef.detailedPage.frequentlyAskedQuestions}
          />
        </Fragment>
      ) : (
        ""
      )}
    </Layout>
  );
};

export default SolutionDetailTemplate;
